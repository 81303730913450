import { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

export function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])

  return (
    <>
      {isVisible && (
        <IconButton
          onClick={scrollToTop}
          style={{ position: "fixed", bottom: "70px", right: "10px" }}
          color="primary"
          sx={{ backgroundColor: "whiteSmoke" }}
          aria-label="scroll to top"
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </>
  )
}
