import { Typography } from "@mui/material"
import { formatDate } from "../../../utils/formatDate"

export const NewsDate = ({ date }: { date: string }) => {
  return (
    <Typography variant="caption" sx={{ color: "darkgray" }}>
      Published at {formatDate(date)}
    </Typography>
  )
}
