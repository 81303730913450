import React, { useCallback, useMemo } from "react"
import { Stack, Chip, useMediaQuery, useTheme, Paper, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { applyFilters, clearFilters } from "../../redux/filters/filters.slice"
import { type RootState } from "../../redux/store"

interface HeaderTagsProps {
  tags: string[]
}

export const HeaderTags: React.FC<HeaderTagsProps> = React.memo(({ tags }: HeaderTagsProps) => {
  const dispatch = useDispatch()
  const newsTagFilters = useSelector((state: RootState) => state.filters.newsTagFilters)
  const selectedTags = useMemo(
    () => tags.filter((tag) => newsTagFilters[tag]),
    [tags, newsTagFilters]
  )
  const handleTagClick = useCallback(
    (tag: string) => {
      dispatch(
        applyFilters({
          filter: "newsTagFilters",
          filterType: tag,
          value: !newsTagFilters[tag],
        })
      )
    },
    [dispatch, newsTagFilters]
  )

  const handleClearAll = useCallback(() => {
    dispatch(clearFilters({ filter: "newsTagFilters" }))
  }, [dispatch])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  if (tags.length === 0) return null

  return (
    <Paper
      sx={{
        position: selectedTags.length > 0 && !isMobile ? "sticky" : "relative",
        top: 0,
        zIndex: 10,
        backdropFilter: selectedTags.length > 0 ? "blur(4px)" : "none",
        backgroundColor: selectedTags.length > 0 ? "rgba(255, 255, 255, 0.8)" : "transparent",
        borderRadius: 0,
        boxShadow: "none",
        "&::after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          height: "5px",
          background: "linear-gradient(to bottom, rgba(0,0,0,0.08), transparent)",
          pointerEvents: "none",
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          gap: 1,
          flexWrap: "wrap",
          justifyContent: "center",
          py: 1,
          position: "relative",
        }}
      >
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            variant={selectedTags.includes(tag) ? "filled" : "outlined"}
            sx={{
              borderRadius: "13px",
            }}
            onClick={() => {
              handleTagClick(tag)
            }}
          />
        ))}

        <Button
          size="small"
          onClick={handleClearAll}
          disabled={selectedTags.length === 0}
          sx={{
            color: "black",
            textTransform: "none",
            borderRadius: "12px",
          }}
        >
          show all
        </Button>
      </Stack>
    </Paper>
  )
})

HeaderTags.displayName = "HeaderTags"
