import { Alert } from "@mui/material"
import { type INewsItem } from "../../types/INews"
import { NewsCard } from "./NewsCard"

interface NewsListProps {
  newsData: INewsItem[] | undefined
}

export const NewsList: React.FC<NewsListProps> = ({ newsData }: NewsListProps) => {
  if (newsData?.length === 0) {
    return (
      <Alert severity="info" sx={{ width: "100%" }}>
        No news articles available at this time.
      </Alert>
    )
  }

  return (
    <>
      {newsData?.map((newsItem: INewsItem) => (
        <NewsCard
          key={newsItem.documentId}
          id={newsItem.id}
          title={newsItem.Title}
          body={newsItem.Body}
          cover={newsItem.Cover}
          gallery={newsItem.Gallery}
          publishedAt={newsItem.publishedAt}
          tag1={newsItem.Tag1}
          tag2={newsItem.Tag2}
          tag3={newsItem.Tag3}
        />
      ))}
    </>
  )
}
