import { Box, Typography, List, ListItem } from "@mui/material"
import { type INewsBodyBlock } from "../../../types/INews"

interface NewsBodyProps {
  body: INewsBodyBlock[]
}

const fontFamily =
  '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const NewsBody = ({ body }: NewsBodyProps) => {
  const renderTextWithFormatting = (child: any) => {
    let text = child.text

    // Handle line breaks
    if (text === "\n") return <br />

    // Apply multiple formatting options
    if (child.bold === true) text = <strong>{text}</strong>
    if (child.italic === true) text = <em>{text}</em>
    if (child.underline === true) text = <u>{text}</u>
    if (child.strikethrough === true) text = <del>{text}</del>
    if (child.code === true)
      text = (
        <code style={{ backgroundColor: "#f0f0f0", padding: "2px 4px", borderRadius: "4px" }}>
          {text}
        </code>
      )

    // Handle links
    if (child.type === "link") {
      return (
        <a
          href={child.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "steelBlue", textDecoration: "none" }}
        >
          {child.children?.[0]?.text}
        </a>
      )
    }

    return text
  }

  const renderNode = (node: INewsBodyBlock, index: number) => {
    switch (node.type) {
      case "paragraph": {
        return (
          <Typography variant="body1" key={index} gutterBottom sx={{ fontFamily }}>
            {node.children.map((child, childIndex) => (
              <span key={childIndex}>{renderTextWithFormatting(child)}</span>
            ))}
          </Typography>
        )
      }

      case "quote": {
        return (
          <Box
            key={index}
            sx={{
              borderLeft: "4px solid grey",
              pl: 2,
              py: 1,
              my: 2,
              bgcolor: "WhiteSmoke",
            }}
          >
            <Typography variant="body1" sx={{ fontStyle: "italic", fontFamily }}>
              {node.children.map((child) => child.text).join("")}
            </Typography>
          </Box>
        )
      }

      case "code": {
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: "Gainsboro",
              borderRadius: 1,
              p: 2,
              my: 2,
              overflow: "auto",
            }}
          >
            <pre style={{ margin: 0 }}>
              <code>{node.children.map((child) => child.text).join("")}</code>
            </pre>
          </Box>
        )
      }

      case "heading": {
        const headingText = node.children.map((child) => child.text).join("")
        // Map heading level to a Typography variant
        let variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" = "h6"
        switch (node.level) {
          case 1:
            variant = "h1"
            break
          case 2:
            variant = "h2"
            break
          case 3:
            variant = "h3"
            break
          case 4:
            variant = "h4"
            break
          case 5:
            variant = "h5"
            break
          case 6:
            variant = "h6"
            break
        }

        return (
          <Typography variant={variant} key={index} gutterBottom sx={{ fontFamily }}>
            {headingText}
          </Typography>
        )
      }

      case "image": {
        if (node.image == null) return null
        const { url, alternativeText } = node.image
        return (
          <Box key={index} my={2}>
            <img
              src={url}
              alt={alternativeText ?? ""}
              className="max-w-full h-auto rounded-[5px]"
            />
          </Box>
        )
      }

      case "list": {
        return (
          <Box key={index} sx={{ pl: 2, my: 1 }}>
            {node.format === "ordered" ? (
              <List component="ol" sx={{ listStyleType: "decimal" }}>
                {node.children?.map((listItem, liIndex) => {
                  const itemText = listItem.children?.[0]?.text ?? ""
                  return (
                    <ListItem key={liIndex} sx={{ display: "list-item", p: 0, mb: 0.5 }}>
                      <Typography variant="body1" sx={{ fontFamily }}>
                        {itemText}
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <List component="ul" sx={{ listStyleType: "disc" }}>
                {node.children?.map((listItem, liIndex) => {
                  const itemText = listItem.children?.[0]?.text ?? ""
                  return (
                    <ListItem key={liIndex} sx={{ display: "list-item", p: 0, mb: 0.5 }}>
                      <Typography variant="body1" sx={{ fontFamily }}>
                        {itemText}
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Box>
        )
      }

      default:
        return null
    }
  }

  return <div>{body.map((node: INewsBodyBlock, index: number) => renderNode(node, index))}</div>
}
