import { CardMedia } from "@mui/material"
import { type INewsImage } from "../../../types/INews"

interface NewsCoverProps {
  cover: INewsImage
}

export const NewsCover = ({ cover }: NewsCoverProps) => {
  if (cover == null) return null
  return (
    <CardMedia
      component="img"
      image={`${process.env.REACT_APP_STRAPI_URL as string}${cover?.url}`}
      alt={cover?.alternativeText ?? ""}
      className="xs:h-[200px] md:h-[250px] lg:h-[350px] w-full object-cover rounded-[5px]"
    />
  )
}
