import { CardActions } from "@mui/material"
import { Tags } from "./NewsCardTags"

interface NewsFooterProps {
  tag1: string
  tag2: string
  tag3: string
}

export const NewsFooter = ({ tag1, tag2, tag3 }: NewsFooterProps) => {
  const tags = [tag1, tag2, tag3].filter((tag) => tag?.length > 0)
  if (tags.length === 0) return null

  return (
    <CardActions>
      <Tags tags={tags} />
    </CardActions>
  )
}
