import { Card, CardContent, CardHeader } from "@mui/material"
import { NewsBody } from "./NewsCard/NewsBody"
import { NewsFooter } from "./NewsCard/NewsFooter"
import { NewsGallery } from "./NewsCard/NewsGallery"
import { NewsCover } from "./NewsCard/NewsCover"
import { NewsDate } from "./NewsCard/NewsDate"
import { type INewsCardItem } from "../../types/INews"

const fontFamily =
  '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const NewsCard: React.FC<INewsCardItem> = ({
  title,
  body,
  cover,
  gallery,
  publishedAt,
  tag1,
  tag2,
  tag3,
  id,
}: INewsCardItem) => {
  return (
    <Card sx={{ marginY: 2, fontFamily }} key={id}>
      <CardHeader
        title={title}
        sx={{
          "& .MuiCardHeader-title": {
            fontFamily,
          },
        }}
      />
      <CardContent>
        <NewsCover cover={cover} />
        <NewsBody body={body} />
        <NewsGallery media={gallery} />
        <NewsDate date={publishedAt} />
      </CardContent>
      <NewsFooter tag1={tag1} tag2={tag2} tag3={tag3} />
    </Card>
  )
}
