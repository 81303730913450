// Third-party libraries
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Alert } from "@mui/material"

// Types
import { type INewsItem } from "../../types/INews"
import { type RootState } from "../../redux/store"

// Redux
import { useGetAllNewsQuery } from "../../redux/business/business.api"

// Components
import { HeaderTags } from "../../components/News/HeaderTags"
import { NewsHeader } from "../../components/News/NewsHeader"
import { NewsList } from "../../components/News/NewsList"

// Layouts
import { ReportSkeleton } from "../../layouts/Skeletons/ReportSkeleton"
import { ScrollTop } from "../../components/ScrollTop"

export const News: React.FC = () => {
  const { data: apiResponse, isLoading, error } = useGetAllNewsQuery(null)
  const newsData = apiResponse?.ApiResponse?.data
  const newsTagFilters = useSelector((state: RootState) => state.filters.newsTagFilters)

  // Sort the newsData by publishedAt in ascending order (earliest first)
  const sortedNewsData = useMemo(() => {
    if (newsData == null || newsData.length === 0) return []
    return [...newsData].sort((a, b) => {
      return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    })
  }, [newsData])

  const tags = useMemo(() => {
    if (sortedNewsData.length === 0) return []
    return Array.from(
      new Set(
        sortedNewsData.flatMap((news: INewsItem) =>
          [news.Tag1, news.Tag2, news.Tag3].filter(Boolean)
        )
      )
    )
  }, [sortedNewsData])

  const filteredNews = useMemo(() => {
    if (sortedNewsData.length === 0) return []

    const activeFilters = Object.entries(newsTagFilters).reduce<string[]>(
      (acc, [tag, isActive]) => {
        if (isActive) acc.push(tag)
        return acc
      },
      []
    )

    if (activeFilters.length === 0) return sortedNewsData

    return sortedNewsData.filter((news) => {
      const newsTags = [news.Tag1, news.Tag2, news.Tag3].filter(Boolean)
      return activeFilters.some((filter) => newsTags.includes(filter))
    })
  }, [sortedNewsData, newsTagFilters])

  useEffect(() => {
    document.body.style.overflowY = "scroll"
    return () => {
      document.body.style.overflowY = "auto"
    }
  }, [])

  return (
    <div className="w-full md:max-w-4xl mx-auto mb-1">
      <NewsHeader title="News" />
      <HeaderTags tags={tags} />
      {isLoading ? (
        <ReportSkeleton />
      ) : error != null ? (
        <Alert severity="error" sx={{ width: "100%" }}>
          Failed to load news items. Please try again later.
        </Alert>
      ) : (
        <NewsList newsData={filteredNews} />
      )}
      <ScrollTop />
    </div>
  )
}
